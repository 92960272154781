export default {
  firstName: 'Bishnu',
  lastName: 'Rai',
  emailAddress: 'rajvison@gmail.com',
  headline: 'Software Developer in Nepal',
  summary: `
    Experienced Chief Technology Officer, Developer  with a demonstrated history of working in the internet industry. Skilled in Python, React, continuous integration, WordPress, Linux System Administration, and Application Programming Interfaces.<br /><br />
    Strong engineering professional with a Licence focused in Software Devlopement from Kathmandu University Nepal.<br /><br />My favourite stack :  ReactJS, ReactNative with continuous integration on Gitlab,Python.
  `,
  pictureUrl: 'bishnu.jpg',
  dateOfBirth: '1995-07-03',
  mainAddress: 'Kathmandu, Nepal',
  websites: [
    {
      type: 'Linkedin',
      url: 'https://www.linkedin.com/in/bishnukhalingrai/',
    },
  ],
  phoneNumbers: [
    {
      type: 'Personal',
      number: '+977 9869162217',
    },
  ],

  imAccounts: [
    {
      type: 'Skype',
      name: 'bishnuskypelivechat',
    }
  ],
  snsAccounts: [
    {
      name: 'Linkedin',
      icon: 'linkedin',
      url: 'https://www.linkedin.com/in/bishnukhalingrai/',
    },
    {
      name: 'Github',
      icon: 'github',
      url: 'https://github.com/Bishnurai701',
    },
  ],
  locations: [
    {
      name: 'Kathmandu',
      country: 'Nepal',
      isCurrent: true,
    }
  ],
  positions: [
    {
      title: 'JAVA API Developer',
      summary:'JAVA development<br />| Spring Boot' ,
      startDate: '2021-09-11',
      endDate: 'Today',
      isCurrent: true,
      company: 'FCGO-Finance Comptroller General Office',
    },
    {
      title: 'Lecture',
      summary:'Computer Science <br />| +2' ,
      startDate: '2020-01-11',
      endDate: '2021-08-15',
      isCurrent: true,
      company: 'Xavier International College',
    },
    {
      title: 'DBA',
      summary:'Database Administrator <br />| SQL/MYSQL' ,
      startDate: '2016-12-01',
      endDate: '2019-05-15',
      isCurrent: true,
      company: 'AL JAZIRA FACTORY',
    },
    {
      title: 'Backend Developer(Intern)',
      summary:'C# and ASP.NET CORE development<br />| .NET Core' ,
      startDate: '2015-06-04',
      endDate: '2016-12-05',
      isCurrent: true,
      company: 'Lubena Infomedia',
    },
    
    // {
    //   title: 'FrontEnd Developer',
    //   summary:'Javascript development<br />| ReactJS' ,
    //   startDate: '2018-02-01',
    //   endDate: '2019-02-01',
    //   isCurrent: true,
    //   company: 'Integrated ICT',
    // },
    // {
    //   title: 'Developer UI/UX',
    //   summary:'Javascript development<br />| ReactJS ',
    //   startDate: '2017-12-01',
    //   endDate: '2018-01-31',
    //   isCurrent: true,
    //   company: 'Bits Innovation',
    // },
    // {
    //   title: 'Trainee Software Test Engineer',
    //   summary:'SQL/Selenium/Burpsuite',
    //   startDate: '2017-07-03',
    //   endDate: '2017-10-13',
    //   isCurrent: true,
    //   company: 'Pioneer Solutions',
    // }
  ],
  interests: '',
  languages: [
    {
      name: 'Nepali',
      level: 'Native',
      levelNumber: 10,
    },
    {
      name: 'English',
      level: 'Professional working proficiency',
      levelNumber: 7,
    },
    {
      name: 'Hindi',
      level: 'Professional working proficiency',
      levelNumber: 7,
    },
    // {
    //   name: 'German',
    //   level: 'Elementary proficiency',
    //   levelNumber: 3,
    // },
  ],
  skills: [
    {
      name: 'Advance',
      language: {
        name: 'JAVA',
        style: 'redhat',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'APIs',
      language: {
        name: 'JAVA',
        style: 'redhat',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'DOT.NET CORE / C#',
      language: {
        name: '.NET CORE',
        style: 'python'
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'React',
      language: {
        name: 'Javascript',
        style: 'javascript',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'React Native',
      language: {
        name: 'Javascript',
        style: 'javascript',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    // {
    //   name: 'Redux',
    //   language: {
    //     name: 'Javascript',
    //     style: 'javascript',
    //   },
    //   category: {
    //     name: 'Frameworks / SDK / CMS',
    //   },
    // },
    // {
    //   name: 'ES6',
    //   language: {
    //     name: 'Javascript',
    //     style: 'javascript',
    //   },
    //   category: {
    //     name: 'Languages',
    //   },
    // },
    // {
    //   name: 'Facebook SDK',
    //   language: {
    //     name: 'Javascript',
    //     style: 'javascript',
    //   },
    //   category: {
    //     name: 'Frameworks / SDK / CMS',
    //   },
    // },
    {
      name: 'Spring Boot',
      language: {
        name: 'JAVA',
        style: 'redhat',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'SQL (MYSQL)',
      language: {
        name: 'Others',
        style: 'others',
      },
      category: {
        name: 'Languages',
      },
    },
    {
      name: 'C# core',
      language: {
        name: 'Others',
        style: 'others',
      },
      category: {
        name: 'Languages',
      },
    },
    {
      name: 'HTML5/CSS3',
      language: {
        name: 'Others',
        style: 'others',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'Bootstrap',
      language: {
        name: 'Others',
        style: 'others',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    {
      name: 'JQUERY',
      language: {
        name: 'Javascript',
        style: 'javascript',
      },
      category: {
        name: 'Frameworks / SDK / CMS',
      },
    },
    // {
    //   name: 'Angular 8',
    //   language: {
    //     name: 'Javascript',
    //     style: 'javascript',
    //   },
    //   category: {
    //     name: 'Frameworks / SDK / CMS',
    //   },
    // },
    // {
    //   name: 'Wordpress',
    //   language: {
    //     name: 'Others',
    //     style: 'others',
    //   },
    //   category: {
    //     name: 'Frameworks / SDK / CMS',
    //   },
    // },
    {
      name: 'Linux',
      category: {
        name: 'Tools',
      },
    },
    {
      name: 'GIT',
      category: {
        name: 'Tools',
      },
    },
    {
      name: 'Gitlab-CI',
      category: {
        name: 'Tools',
      },
    },
    {
      name: 'GITLAB/GITHUB',
      category: {
        name: 'Tools',
      },
    },
  ],

  tools:
    'Operating system: Kali Linux(over one years)<br />IDE: Eclipse / STS / IntelliJ /VS Code<br />Continuous integration software: Gitlab-CI<br />Project Management Software: Gitlab<br />Version control system software: Gitlab / Github/SVN<br />',
  educations: [
    {
      schoolName: 'OPJS University,India',
      fieldOfStudy: 'Bachelor of Computer Application(BCA)',
      startDate: '2013',
      endDate: '2016',
      degree: 'Undergraduate',
      activities:
        'Database Mangement System, C++,Theory Of Computation,Computer Networks',
    },
    {
      schoolName: 'Grammar Higher Secondary School | College',
      fieldOfStudy: 'Management (B.Math and Computer)',
      startDate: '2009',
      endDate: '2011',
      degree: 'Intermediate',
      activities: 'Programming, Mathematics,Management',
    },
  ],



  projects: [
    {
      title: 'TSA | CGAS',
      subtitle: 'Web Project',
      category: 'Government Accounting & Financial Reporting System',
      subcategory: 'react',
      contribution: true,
      links: [
        {
          url: '#',
          text: 'Confidential Project',
        },
      ],

      technologies: [
        {
          name: 'Java',
        },
        {
          name: 'Spring Boot',
        },
      ],
      content: 'Government Accounting & Financial Reporting System for Ministry Of Finance',
      date: '2018-03-08',
    },

    {
      title: 'PAMS',
      subtitle: 'Web Project',
      category: 'License Module for Venio system',
      subcategory: 'react',
      contribution: true,
      links: [
        {
          url: '#',
          text: 'Confidential Project',
        },
      ],

      technologies: [
        {
          name: 'Java',
        },
        {
          name: 'Spring Boot',
        },
      ],
      content:'Public Assets Management System for Government Office',
      date: '2018-03-08',
    },
    // {
    //   title: 'Integrated ICT ',
    //   subtitle: 'Website',
    //   category: 'Content Management System',
    //   subcategory: 'react',
    //   contribution: true,
    //   links: [
    //     {
    //       url: 'https://safe-fjord-65589.herokuapp.com/?fbclid=IwAR3aLhu3bmXxsGvxCFptk5JGxUtn5OwZm_dJjRYM2sdSRMxYhgu7r_77rLU#/',
    //       text: 'Visit Website ',
    //     },
    //   ],

    //   technologies: [
    //     {
    //       name: 'React',
    //     },
    //     {
    //       name: 'CSS',
    //     },
    //   ],
    //   content:
    //     'Website of company name Integrated ICT Pvt Ltd, this website design changes according to the content.',
    //   date: '2018-03-08',
    // },
    // {
    //   title: 'Semantro',
    //   subtitle: 'Website',
    //   category: 'Content Management System',
    //   subcategory: 'react',
    //   links: [
    //     {
    //       url:
    //         'https://www.semantro.com',
    //       text: 'Visit Website',
    //     },
    //   ],
    //   technologies: [
    //     {
    //       name: 'React',
    //     },
    //     {
    //       name: 'CSS',
    //     },
    //   ],
    //   content: 'Website of company name Semantro Pvt Ltd, this website design changes according to the content.',
    //   date: '2018-09-26',
    // },
    // {
    //   title: 'Tea Nepal',
    //   subtitle: 'Website',
    //   category: 'project',
    //   subcategory: 'react',
    //   links: [
    //     {
    //       url: 'https://calm-inlet-98561.herokuapp.com/?fbclid=IwAR2VY9slzRuM3X_xoaUYE6YyMI-cGdvy8dxRwwUc6I8O4AxBCaC57BXQlYU',
    //       text: 'Visit website',
    //     },

    //   ],
    //   technologies: [
    //     {
    //       name: 'React',
    //     },
    //     {
    //       name: 'CSS',
    //     },
    //   ],
    //   content: 'Showcase websites of the International Tea Event.',
    //   date: '2018-02-1',
    // },
    // {
    //   title: 'Bits Innovation',
    //   subtitle: 'Website',
    //   category: 'project',
    //   subcategory: 'react',
    //   links: [
    //     {
    //       url: 'http://www.bitsinnovation.com/',
    //       text: 'Visit website',
    //     },

    //   ],
    //   technologies: [
    //     {
    //       name: 'React',
    //     },
    //     {
    //       name: 'CSS',
    //     },
    //   ],
    //   content: 'Showcase websites of the Bitsinnovation company.',
    //   date: '2018-01-10',
    // },
  ],
  volunteer: [
    {
      name: '',
    },
  ],
  hobbies: [
    {
      name: 'Football',
      icon: 'sports_soccer',
      description: 'Playing & Watching',
    },
    {
      name: 'Travelling',
      icon: 'flight',
      description: 'Alone or with someone.',
    },
    {
      name: 'Learning languages',
      icon: 'school',
      description: 'Hindi and German languages.',
    },
    {
      name: 'Biking',
      icon: 'directions_bike',
      description: "When it's sunny.",
    },
    {
      name: 'Photography',
      icon: 'photo_camera',
      description: 'Of landscapes.',
    },
    {
      name: 'Music',
      icon: 'music_video',
      description: 'Soothing.',
    },
  ],
};
